import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { Toast } from '@capacitor/toast'
import { sleep } from '../util/helpers'
import slugify from 'slugify'

import {
  ResponseItemLocation,
  ResponseItemMeasurement,
  ResponseItemProject,
  ResponseListClients,
  ResponseListLocationsMap,
  ResponseListProjects,
  ResponseListRegions,
} from '../interfaces/api'
import {
  ItemLocation,
  ItemMeasurement,
  ListLocationsMap,
  ListProjects,
  ListRegions,
} from '../interfaces/data'

import { DataService } from './data.service'
import { ApiService } from './api.service'
import { NavigationService } from './navigation.service'
import { TrashService } from './trash.service'
import { CacheService } from './cache.service'
import { Browser } from '@capacitor/browser'
import { PlatformService } from './platform.service'
import { IFile } from '../interfaces/etc'
import { Position } from '@capacitor/geolocation'
import { GeolocationService } from './geolocation.service'
import { PermissionsService } from './permissions.service'
import { RecordwavService } from './recordwav.service'

@Injectable({
  providedIn: 'root',
})
export class TrunkService {
  constructor(
    public api: ApiService,
    public cache: CacheService,
    public data: DataService,
    public geolocation: GeolocationService,
    public nav: NavigationService,
    public permissions: PermissionsService,
    public platform: PlatformService,
    public recordwav: RecordwavService,
    public trash: TrashService
  ) {
    this.onInit()
  }

  /* Lifecycle */
  private async onInit() {
    console.info('TrunkService.onInit()..')

    // subscriptions
    this.subscribeLoadingSequence()
    this.subscribeNavigation()

    // pass dark mode from cache to trash
    if (this.cache.cache.settings.darkMode) {
      this.trash.darkMode$.next(this.cache.cache.settings.darkMode)
    }

    // permissions
    await this.permissions.checkPermissions(this.platform.platformType)
  }

  /* Pipes */
  private async subscribeNavigation() {
    console.debug('subscribeNavigation()..')
    this.nav.navPageTarget$.subscribe({
      complete: async () => {
        console.info('Unsubscribe: navCombinePageTargetAndHandle')
      },
      error: async (err) => {
        console.debug(err)
      },
      next: async (nPT) => {
        // debug
        console.debug(JSON.stringify(nPT))

        // handle data load flags
        if (nPT.dataRequest) {
          console.debug('1. handle data request..')
          nPT.handleUrl = '/loading'
          nPT.status = nPT.dataRequest
          nPT.dataRequest = undefined
          this.nav.navPageTarget$.next(nPT)
          return
        }

        // handle data loading
        if (nPT.handleUrl == '/loading') {
          console.debug('2a. navigate to loading page..')
          this.nav.navCtl.navigateForward('/loading', { skipLocationChange: true }) // move to loading page
          console.debug('2b. instructed to go to loading page..')
          if (nPT.status == 'load') {
            this.data.pageLoaded[nPT.targetUrl].next({ status: 1 })
          }
          return
        }

        // handle navigation
        if (nPT.status !== 'send') {
          console.debug('3. navigate to page: ', JSON.stringify(nPT.targetUrl))
          if (nPT.direction == 'forward') {
            console.debug('3a. forward')
            this.nav.navCtl.navigateForward(nPT.targetUrl)
          } else {
            console.debug('3b. root')
            await this.nav.navCtl.navigateRoot(nPT.targetUrl)
          }
          return
        }

        // handle report
        console.debug('4. no navigation required..')
        console.debug(JSON.stringify(nPT))
      },
    })
    this.nav.navPageReport$.subscribe({
      complete: () => {
        console.debug('Unsubscribe: navPageReport')
      },
      error: (err) => {
        console.debug(err)
      },
      next: (nPR) => {
        // console.debug(JSON.stringify(nPR))
        let nPT = this.nav.navPageTarget$.value
        // console.debug(JSON.stringify(nPT))
        if (nPR == nPT.targetUrl && nPR !== nPT.handleUrl) {
          nPT.handleUrl = nPR
          this.nav.navPageTarget$.next(nPT)
        }
      },
    })
  }

  private async subscribeLoadingSequence() {
    this.data.pageLoaded['/tabs'].subscribe({
      next: async (loadingValue) => {
        console.debug('/tabs - loading status set to ', loadingValue.status)

        switch (loadingValue.status) {
          case 0:
            break
          case 1:
            //  set dependent flags
            this.data.pageLoaded['/tabs/loc'].next({ status: 0 })

            break
          case 2:
            await this.getClients()

            //  action - follow up
            this.data.pageLoaded['/tabs/loc'].next({ status: 1 })

            // report - data has loaded for page
            this.nav.navPageReport$.next('/tabs')
        }
      },
      error: async (error) => {
        console.error(error)
      },
      complete: async () => {
        console.info('Completed: 0. Server')
      },
    })
    this.data.pageLoaded['/tabs/map'].subscribe({
      next: async (loadingValue) => {
        console.debug('/tabs/map - loading status set to ', loadingValue.status)
        switch (loadingValue.status) {
          case 0:
            // action - clearing
            this.data.regions.next([] as ListRegions[])
            this.data.locationsMap.next([] as ListLocationsMap[])

            break
          case 1:
            await this.getRegions()
            await this.getLocationsMap()

            break
          case 2:
            //  action - follow up

            // report - data has loaded for page
            this.nav.navPageReport$.next('/tabs/map')
        }
      },
      error: async (error) => {
        console.error(error)
      },
      complete: async () => {
        console.info('Completed: /tabs/map')
      },
    })
    this.data.pageLoaded['/tabs/loc'].subscribe({
      next: async (loadingValue) => {
        console.debug('/tabs/loc - loading status set to ', loadingValue.status)
        switch (loadingValue.status) {
          case 0:
            // action - clearing
            this.data.projects.next([] as ListProjects[])

            break
          case 1:
            //  set dependent flags
            this.data.pageLoaded['/tabs/loc/l'].next({ status: 0 })

            // action - execute if parent loaded
            if (this.data.pageLoaded['/tabs'].value.status == 2) {
              await this.getProjects()
            } else if (this.data.pageLoaded['/tabs'].value.status !== 1) {
              this.data.pageLoaded['/tabs'].next({ status: 1 })
            }

            break
          case 2:
            //  action - follow up
            this.data.pageLoaded['/tabs/map'].next({ status: 1 })

            // report - data has loaded for page
            this.nav.navPageReport$.next('/tabs/loc')
        }
      },
      error: async (error) => {
        console.error(error)
      },
      complete: async () => {
        console.info('Completed: /tabs/loc')
      },
    })
    this.data.pageLoaded['/tabs/loc/l'].subscribe({
      next: async (loadingValue) => {
        console.debug('/tabs/loc/l - loading status set to ', loadingValue.status)
        switch (loadingValue.status) {
          case 0:
            // action - clearing
            this.data.location.next({} as ItemLocation)

            break
          case 1:
            //  set dependent flags
            this.data.pageLoaded['/tabs/loc/l/m'].next({ status: 0 })

            // action - execute
            await this.getLocation(this.trash.selectedLocationID)

            break
          case 2:
            //  action - follow up

            // report - data has loaded for page
            this.nav.navPageReport$.next('/tabs/loc/l')
        }
      },
      error: async (error) => {
        console.error(error)
      },
      complete: async () => {
        console.info('Completed: /tabs/loc/l')
      },
    })
    this.data.pageLoaded['/tabs/loc/l/m'].subscribe({
      next: async (loadingValue) => {
        console.debug('/tabs/loc/l/m - loading status set to ', loadingValue.status)
        switch (loadingValue.status) {
          case 0:
            // action - clearing
            this.data.measurement.next({} as ItemMeasurement)

            break
          case 1:
            //  set dependent flags

            // action - execute
            await this.getMeasurement(this.trash.selectedMeasurementID)

            // set flags loading sequence

            break
          case 2:
            //  action - follow up

            // report - data has loaded for page
            this.nav.navPageReport$.next('/tabs/loc/l/m')
        }
      },
      error: async (error) => {
        console.error(error)
      },
      complete: async () => {
        console.info('Completed: /tabs/loc/l/m')
      },
    })
  }

  /* Data loading (GET,GET<item>) (services: api, cache, ram) */
  public async getClients() {
    console.debug('getClients()..')

    // call API
    let apiCall = this.api.getClients(this.cache.personals.token)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: getClients()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: getClients()..')
        await this.processError(error)
        this.nav.navHome()
      },
      next: async (response: ResponseListClients) => {
        // show http response
        this.api.showHttpResponse(response)

        console.debug('Assign clients')
        this.data.client.next(response.data[0])
        console.debug(this.data.client.value)
      },
    })
  }

  public async getProjects() {
    console.debug('getProjects()..')

    // call API
    let apiCall = this.api.getProjects(this.cache.personals.token)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: getProjects()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: getProjects()..')
        await this.processError(error)
        this.nav.navHome()
      },
      next: async (response: ResponseListProjects) => {
        // show http response
        this.api.showHttpResponse(response)

        // set server version
        this.trash.besVersion = response.besVersion

        // set user type and level
        this.trash.userType = response.userType
        this.trash.userLevel = response.userLevel

        console.debug('Assign projects')
        this.data.projects.next(response.data)

        // set selected project if only one available
        if (this.data.projects.value.length == 1) {
          this.cache.cache.settings.locations.projects = [this.data.projects.value[0].id]
        }

        // set flags loading sequence
        this.data.pageLoaded['/tabs/loc'].next({ status: 2 })
      },
    })
  }

  public async getRegions() {
    console.debug('getRegions()..')

    // call API
    let apiCall = this.api.getRegions(this.cache.personals.token)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: getRegions()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: getRegions()..')
        await this.processError(error)
        this.nav.navHome()
      },
      next: async (response: ResponseListRegions) => {
        // show http response
        this.api.showHttpResponse(response)

        console.debug('Assign regions')
        this.data.regions.next(response.data)
        console.debug(this.data.regions.value)

        // set selected region if only one available
        if (this.data.regions.value.length == 1) {
          this.cache.cache.settings.map.regions = [this.data.regions.value[0].id]
        }
      },
    })
  }

  public async getLocationsMap() {
    console.debug('getLocationsMap()..')

    // construct filter
    console.debug(this.cache.cache.settings.locations.projects)
    console.debug(this.cache.cache.settings.locations.projects.toString())
    let filter = ''
    if (
      this.cache.cache.settings.map.display == 'projects' &&
      this.cache.cache.settings.locations.projects.length > 0
    ) {
      filter = '&project=' + this.cache.cache.settings.locations.projects.toString()
    }
    if (
      this.cache.cache.settings.map.display == 'regions' &&
      this.cache.cache.settings.map.regions.length > 0
    ) {
      filter = '&region=' + this.cache.cache.settings.map.regions.toString()
    }

    // call API
    let apiCall = this.api.getLocationsMap(this.cache.personals.token, filter)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: getLocationsMap()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: getLocationsMap()..')
        await this.processError(error)
        this.nav.navHome()
      },
      next: async (response: ResponseListLocationsMap) => {
        // show http response
        this.api.showHttpResponse(response)

        console.debug('Assign map locations')
        this.data.locationsMap.next(response.data)

        // set flags loading sequence
        this.data.pageLoaded['/tabs/map'].next({ status: 2 })
      },
    })
  }

  public async getLocation(locationID: number) {
    console.debug('getLocation(): ' + locationID)
    // init
    this.trash.loadingValue = 0.1

    // call API
    let apiCall = this.api.getLocation(this.cache.personals.token, locationID)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: getLocation()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: getLocation()..')
        await this.processError(error)
        await this.nav.navLocations()
      },
      next: async (response: ResponseItemLocation) => {
        // show http response
        this.api.showHttpResponse(response)

        console.debug('Assign location:' + locationID)
        this.data.location.next(response.data)

        // set display values
        await this.data.setLocationMeasurementsDisplay(
          this.cache.cache.settings.locations.reference
        )

        // set flags loading sequence
        console.debug(this.data.location.value.id)
        this.data.pageLoaded['/tabs/loc/l'].next({ status: 2 })
      },
    })
  }

  public async getMeasurement(measurementID: number) {
    console.debug('getMeasurement(): ' + measurementID)
    // // init
    // this.trash.loadingValue = 0.1;

    // call API
    let apiCall = this.api.getMeasurement(this.cache.personals.token, measurementID)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: getMeasurement()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: getMeasurement()..')
        console.error(error)
        this.processError(error)
      },
      next: async (response: ResponseItemMeasurement) => {
        // show http response
        this.api.showHttpResponse(response)

        console.debug('Assign measurement:' + measurementID)
        this.data.measurement.next(response.data)

        // poll for measurement
        this.measurementPoll()
      },
    })
  }

  private async measurementPoll() {
    if (await this.measurementStopPolling()) {
      // set flags loading sequence
      this.trash.loadingValue = 1
      this.data.pageLoaded['/tabs/loc/l/m'].next({ status: 2 })

      // reset counter for polling
      this.trash.counterPoll = this.trash.nCounterPoll
    } else {
      // adjust counter and loading bar
      this.trash.counterPoll = this.trash.counterPoll - 1
      this.trash.loadingValue = 1 - this.trash.counterPoll / this.trash.nCounterPoll

      // wait
      await sleep(this.cache.cache.settings.measurements.pollInterval)

      // try again
      this.data.pageLoaded['/tabs/loc/l/m'].next({ status: 1 })
    }
  }

  private async measurementStopPolling() {
    // if complete, stop here
    if (this.data.measurement.value.status_processing == 'proc_completed') {
      return true
    }

    // if finished polling, stop here
    if (this.trash.counterPoll < 0) {
      Toast.show({
        text: 'Processing took too long, please come back later..',
        duration: 'short',
        position: 'bottom',
      })
      return true
    }

    // check if measurement has timed out - set to older than 5 minutes
    let timestamp = new Date(this.data.measurement.value.timestamp).getTime()
    let now = Date.now()
    let minutes = (now - timestamp) / (60 * 1000)
    if (minutes > 5) {
      Toast.show({
        text: 'Measurement has timed out..',
        duration: 'short',
        position: 'bottom',
      })
      return true
    }

    // if error, stop here
    if (this.data.measurement.value.status_processing.includes('Error')) {
      console.debug('Stop polling: algo error..')
      Toast.show({
        text: 'Algorithm error.. you may want to put a manual value',
        duration: 'long',
        position: 'bottom',
      })
      return true
    }

    // default return false to keep polling
    return false
  }

  /* Data sending (POST,PUT,PATCH,DELETE)*/
  public async postAuthenticate() {
    console.debug('postAuthenticate()')
    // init
    this.trash.loadingValue = 0.1

    // call API
    let apiCall = this.api.postAuthenticate(this.cache.personals.username, this.trash.password)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: postAuthenticate()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: postAuthenticate()..')
        this.processError(error)
        // this.nav.navHome();
      },
      next: async (response) => {
        // show http response
        // this.api.showHttpResponse(response);

        // assign token
        this.cache.personals.token = response.key

        // write cache
        this.cache.writeCacheToLocalStorage()

        // set flags loading sequence
        this.data.pageLoaded['/tabs'].next({ status: 1 })

        // process login
        this.processLogin()
      },
    })
  }

  public async postRegister(username: string, email: string, password1: string, password2: string) {
    //  post

    // call API
    let apiCall = this.api.postRegister(username, email, password1, password2)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: postRegister()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: postRegister()..')
        await this.processError(error)
      },
      next: async (response) => {
        // show http response
        // this.api.showHttpResponse(response);

        // set flags loading sequence

        // navigate
        await this.nav.navHome()
      },
    })
  }

  public async patchProject(kwargs: { projectID: number; body: FormData }) {
    console.debug('patchProject(): ' + kwargs.projectID)
    // init
    this.trash.loadingValue = 0.1

    // navigate
    this.nav.navLocations('send')

    // debug
    kwargs.body.forEach((value, key) => console.debug(key + ': ', value))

    // call API
    let apiCall = this.api.patchProject(this.cache.personals.token, kwargs.projectID, kwargs.body)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: patchProject()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: patchProject()..')
        this.processError(error)
        this.nav.navLocations()
      },
      next: async (response: ResponseItemProject) => {
        // show http response
        this.api.showHttpResponse(response)

        // set load flag
        this.data.pageLoaded['/tabs/loc'].next({ status: 1 })

        // navigate load after send
        this.nav.navLoadAfterSend()
      },
    })
  }

  public async postLocation(projectID: number, engineName: string) {
    console.debug('postLocation()')
    // init
    this.trash.loadingValue = 0.1

    // navigate
    this.nav.navLocation('send')

    // prepare data
    let date = new Date()
    let location_id = date.getTime().toString()
    let nameStringNoSlug: string = location_id
    let nameStringSlug: string = slugify(nameStringNoSlug, {
      replacement: '-', // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: false, // convert to lower case, defaults to `false`
      strict: false, // strip special characters except replacement, defaults to `false`
      locale: 'vi', // language code of the locale to use
    })

    // body
    let body = new FormData()
    body.append('name', nameStringSlug)
    body.append('engine', engineName)
    body.append('project', projectID.toString())
    body.forEach((value, key) => console.debug(key + ': ', value))

    // call API
    let apiCall = this.api.postLocation(this.cache.personals.token, body)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: postLocation()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: postLocation()..')
        this.processError(error)
        this.nav.navLocations()
      },
      next: async (response) => {
        // show http response
        this.api.showHttpResponse(response)

        // assign location
        this.trash.selectedLocationID = response.data.id

        // navigate load after send
        this.nav.navLoadAfterSend()
      },
    })
  }

  public async patchLocation(locationID: number, body: FormData) {
    console.debug('patchLocation(): ' + locationID)
    // init
    let locationNotPublicAnymore: boolean = false
    let locationPositionUpdated: boolean = false
    this.trash.loadingValue = 0.1

    // navigate
    this.nav.navLocation('send')

    // inspect body
    body.forEach((value, key) => {
      console.debug(key + ': ', value)
      if (key == 'public' && value == 'false') {
        locationNotPublicAnymore = true
      }
      if (key == 'lat' || key == 'lon') {
        locationPositionUpdated = true
      }
    })

    // call API
    let apiCall = this.api.patchLocation(this.cache.personals.token, locationID, body)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: patchLocation()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: patchLocation()..')
        this.processError(error)
        this.nav.navLocations()
      },
      next: async (response) => {
        // show http response
        this.api.showHttpResponse(response)

        // refresh map locations if public
        if (response.data.public || locationNotPublicAnymore || locationPositionUpdated) {
          this.trash.didEnterRefreshLocationsMap = true
        }

        // assign location
        this.data.location.next(response.data)

        // set flags loading sequence
        this.data.pageLoaded['/tabs/loc'].next({ status: 1 })

        // navigate load after send
        this.nav.navLoadAfterSend()
      },
    })
  }

  public async deleteLocation(locationID: number) {
    console.debug('deleteLocation(): ' + locationID)
    // init
    this.trash.loadingValue = 0.1

    // navigate
    this.nav.navLocations('send')

    // call API
    let apiCall = this.api.deleteLocation(this.cache.personals.token, locationID)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: deleteLocation()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: deleteLocation()..')
        this.processError(error)
        this.nav.navLocations()
      },
      next: async (response) => {
        // show http response
        this.api.showHttpResponse(response)

        // clear location
        this.data.location.next({} as ItemLocation)

        // navigate load after send
        this.nav.navLoadAfterSend()
      },
    })
  }

  public async postMeasurement(
    measurementFile: IFile,
    deviceTune?: string,
    deviceVolumeSoftware?: number,
    position?: Position | null
  ) {
    console.debug('postMeasurement()')

    // prepare loading page
    this.trash.loadingDisplay = 'bar'
    this.trash.loadingValue = 0.1

    // navigate
    this.nav.navMeasurement('send')

    try {
      console.debug(measurementFile.blob.size)
      if (measurementFile.blob.size > 0) {
        await sleep(500)
      } else {
        throw new Error('Something wrong with the uploadfile..')
      }
    } catch (error) {
      console.error(error)
      this.reportContext(' measurementFile.blob.size', measurementFile.blob.size)
      this.reportContext(' measurementFile.filePath', measurementFile.filePath)
      this.reportException(error)
    }

    // body upload form
    let body = new FormData()
    body.append('timestamp', new Date().toJSON())
    if (measurementFile !== null) {
      body.append('recording', measurementFile.blob, measurementFile.filePath)
    }
    body.append('location', this.data.location.value.id.toString())
    if (deviceTune) {
      body.append('dev_tune', deviceTune)
    }
    if (deviceVolumeSoftware) {
      body.append('dev_volume_software', deviceVolumeSoftware.toString())
    }
    body.append('dev_type', this.trash.deviceInfo.model)
    body.append('app_version', this.cache.appVersion.tag)

    // conditional append position
    if (typeof position !== 'undefined' && position !== null) {
      body.append('dev_accuracy', position.coords.accuracy.toString())
      body.append('dev_lon', position.coords.longitude.toString())
      body.append('dev_lat', position.coords.latitude.toString())
    }

    // debug
    body.forEach((value, key) => console.debug(key + ': ', value))

    // call API
    let apiCall = this.api.postMeasurement(this.cache.personals.token, body)

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: postMeasurement()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: postMeasurement()..')
        await this.processError(error)
        await this.nav.navLocation()
      },
      next: async (response) => {
        // show http response
        this.api.showHttpResponse(response)

        // check and storemeasurement id
        if (response.data.id == null || response.data.id == undefined) {
          let errorTxt = 'Error - measurement has no ID..'
          Toast.show({
            text: errorTxt,
            duration: 'long',
            position: 'bottom',
          })
          // navigate out
          this.nav.navLocation()
        } else {
          console.debug('Assign measurement')
          this.data.measurement.next(response.data)
          this.trash.selectedMeasurementID = response.data.id

          // navigate load after send
          this.nav.navLoadAfterSend()
        }
      },
    })
  }

  public async patchMeasurement() {
    // init
    this.trash.loadingValue = 0.1

    // navigate
    this.nav.navLocation('send')

    // body
    let body = new FormData()

    // required
    try {
      body.append('value_reviewed', this.data.measurement.value.value_reviewed.toString())
    } catch (error) {
      console.debug(error)
      Toast.show({
        text: 'No measurement review value..',
        duration: 'long',
        position: 'bottom',
      })
      await this.nav.navLocation()
      return
    }

    // set review status
    try {
      if (this.data.measurement.value.value_alg == this.data.measurement.value.value_reviewed) {
        body.append('status_review', 'rev_approved')
      } else {
        body.append('status_review', 'rev_adjusted')
      }
    } catch (error) {
      console.debug(error)
      Toast.show({
        text: 'No measurement algo value..',
        duration: 'long',
        position: 'bottom',
      })
      await this.nav.navLocation()
      return
    }

    // debug
    body.forEach((value, key) => console.debug(key + ': ', value))

    // call API
    let apiCall = this.api.patchMeasurement(
      this.cache.personals.token,
      this.data.measurement.value.id,
      body
    )

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: patchMeasurement()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: patchMeasurement()..')
        this.processError(error)
        await this.nav.navLocation()
      },
      next: async (response) => {
        // show http response
        this.api.showHttpResponse(response)

        console.debug('Assign measurement')
        this.data.measurement.next(response.data)

        // navigate load after send
        this.nav.navLoadAfterSend()
      },
    })
  }

  public async deleteMeasurement() {
    // init
    this.trash.loadingValue = 0.1

    // navigate
    this.nav.navLocation('send')

    // call API
    let apiCall = this.api.deleteMeasurement(
      this.cache.personals.token,
      this.data.measurement.value.id
    )

    // subscribe for result
    apiCall.subscribe({
      complete: async () => {
        console.info('Completed: deleteMeasurement()')
      },
      error: async (error: HttpErrorResponse) => {
        console.error('Error: deleteMeasurement()..')
        this.processError(error)
        this.nav.navLocation()
      },
      next: async (response) => {
        // show http response
        this.api.showHttpResponse(response)

        console.debug('Remove measurement from memory')
        this.data.measurement.next({} as ItemMeasurement)

        // navigate load after send
        this.nav.navLoadAfterSend()
      },
    })
  }

  /* Error reporting  */
  public async reportLog(message: string) {
    console.debug(message)
    if (this.trash.crashlyticsEnabled) {
      // await this.report.crashlyticsLogMessage(message)
    } else {
      console.debug('reportLog() - crashlytics not enabled..')
    }
  }

  public async reportContext(key: string, value: string | number | boolean) {
    if (this.trash.crashlyticsEnabled) {
      // this.report.crashlyticsSetContext(key, value);
    } else {
      console.debug('reportContext() - crashlytics not enabled..')
    }
  }

  public async reportException(error: any) {
    console.error(error)
    if (this.trash.crashlyticsEnabled) {
      // await this.report.crashlyticsSetContext('cache', JSON.stringify(this.cache.cache));
      // await this.report.crashlyticsException(error);
    } else {
      console.debug('reportException() - crashlytics not enabled..')
    }
  }

  /* Processors */
  private async processError(error: HttpErrorResponse) {
    this.api.showHttpError(error)
  }

  public async processLogin() {
    console.debug('processLogin()')

    // start with a token check
    try {
      if (!this.cache.checkUserHasToken()) {
        throw new Error('Something is wrong with your token..')
      }
    } catch (error: any) {
      console.error('processLogin() - error')
      await this.processError(error)
      await this.processLogout()
      return
    }

    // setting in trash whether user is testuser or not
    if (this.trash.testUsers.has(this.cache.personals.username)) {
      console.debug('Testuser detected!')
      this.trash.isTestUser = true
    }

    // set flags loading sequence
    this.data.pageLoaded['/tabs'].next({ status: 2 })

    // navigate
    if (
      this.cache.cache.appFlow.showInstructionsFirstLoad &&
      !(this.cache.personals.username == 'ddi' || this.cache.personals.username == 'dirk')
    ) {
      this.nav.navInstructions()
    } else {
      this.nav.navTabs()
    }
  }

  public async processLogout() {
    console.debug('processLogout()')
    await this.cache.clearLocalStorage()
    await this.cache.initialisePersonals()
    this.trash.userHasLoggedOut = true
    await this.nav.navHome()
  }

  /* Helpers */
  public async navExtAccount() {
    let accountUrl = this.api.besUrl + 'account/'
    console.debug(accountUrl)
    await Browser.open({ url: accountUrl })
  }

  public async navForgotPassword() {
    let accountUrl = this.api.besUrl + 'account/password/reset/'
    console.debug(accountUrl)
    await Browser.open({ url: accountUrl })
  }

  public async navSendFeedback() {
    await Browser.open({ url: 'mailto:feedback@groundwater.global' })
  }
}
