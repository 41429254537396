import { Injectable } from '@angular/core'
import { Route, Router } from '@angular/router'
import { Toast } from '@capacitor/toast'
import { TrunkService } from './trunk.service'
import { ModalService } from './modal.service'

@Injectable({
  providedIn: 'root',
})
export class RootService {
  constructor(public modal: ModalService, public router: Router, public trunk: TrunkService) {
    this.onInit()
  }

  /* Lifecycle */
  private async onInit() {
    console.info('RootService.onInit()..')
  }

  /* On handles */
  public async onLogin() {
    console.debug('onLogin()')

    // check credentials
    let goodCredentials = !(
      this.trunk.cache.personals.username === null ||
      this.trunk.cache.personals.username === '' ||
      this.trunk.cache.personals.username === undefined ||
      this.trunk.trash.password === null ||
      this.trunk.trash.password === '' ||
      this.trunk.trash.password === undefined
    )

    // with good credentials, try to get a token
    if (goodCredentials) {
      this.trunk.postAuthenticate()
    } else {
      Toast.show({
        text: 'Please fill in your username and password..',
        duration: 'long',
        position: 'bottom',
      })
    }
  }

  public async onRefreshLocations() {
    this.trunk.nav.navLocations('load')
  }

  public async onRefreshLocation() {
    this.trunk.nav.navLocation('load')
    await this.trunk.trash.refreshChart()
  }

  public async onRefreshMeasurement() {
    this.trunk.nav.navMeasurement('load')
  }

  public async onChangeSettingsMap() {
    this.trunk.trash.didEnterRefreshLocationsMap = true
  }

  /* Helpers */
  public printpath(parent: string, config: Route[]) {
    for (let i = 0; i < config.length; i++) {
      const route = config[i]
      console.log(parent + '/' + route.path)
      if (route.children) {
        const currentPath = route.path ? `${parent}/${route.path}` : parent
        this.printpath(currentPath, route.children)
      }
    }
  }

  public getReferenceLabel(ref: string = '') {
    let reference = ref
    if (ref == '' || ref == 'top') {
      reference = 'Top of pipe [m]'
    }
    if (ref == 'hag') {
      reference = 'Ground level [m] '
    }
    if (ref == 'hamsl') {
      reference = 'Mean sea level [m]'
    }
    return reference
  }
}
