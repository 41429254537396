import { Injectable } from '@angular/core'
import { Device, DeviceInfo } from '@capacitor/device'
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class TrashService {
  // test users
  public testUsers = new Set(['dirk'])
  public isTestUser: boolean = false
  public isTesting: boolean = false

  // random stuff
  public besVersion: string = ''
  public production: boolean = true
  public crashlyticsEnabled = false
  public appInitialized: boolean = false
  public deviceInfo!: DeviceInfo
  public loadingValue: number = 0
  public selectedLocationID!: number
  public selectedMeasurementID!: number
  public counterPoll!: number
  public nCounterPoll: number = 10
  public password: string = ''
  public userType: string = 'community' // 'community' or 'professional'
  public userLevel: string = 'regular' // 'regular' or 'manager'
  public userHasLoggedIn: boolean = false
  public userHasLoggedOut: boolean = false
  public darkMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public loadingDisplay: string = 'sequence' // 'sequence' or 'bar'
  public displayInstructions: string = 'basics' //
  public displayLocation: string = 'overview' // 'overview', 'edit', 'position'
  public displayMeasurementEdit: boolean = false
  public displaySetting: string = 'general'
  public didEnterRefreshLocationsMap: boolean = false
  public showChart: boolean = false

  constructor() {
    this.production = environment.production
  }

  /* Lifecycle */
  async onInit() {
    console.info('TrashService.onInit()..')
    this.counterPoll = this.nCounterPoll
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('TrashService.init()..')
      await this.onInit()
      this.appInitialized = true
      this.deviceInfo = await Device.getInfo()
      console.info('TrashService: Ready')
      resolve()
    })
  }

  /* Service Functions */
  public async refreshChart() {
    this.showChart = false
    this.showChart = true
  }

  public async handleDisplayLocation(page: string) {
    this.displayLocation = page
  }
}
