import { Injectable } from '@angular/core'
import {
  IPageLoad,
  ItemLocation,
  ItemMeasurement,
  ListClients,
  ListLocationsMap,
  ListProjects,
  ListRegions,
  NestedItemEngine,
} from '../interfaces/data'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // data
  public client: BehaviorSubject<ListClients> = new BehaviorSubject({} as ListClients)
  public locationsMap: BehaviorSubject<ListLocationsMap[]> = new BehaviorSubject([] as ListLocationsMap[])
  public projects: BehaviorSubject<ListProjects[]> = new BehaviorSubject([] as ListProjects[])
  public regions: BehaviorSubject<ListRegions[]> = new BehaviorSubject([] as ListRegions[])
  public location: BehaviorSubject<ItemLocation> = new BehaviorSubject({} as ItemLocation)
  public measurement: BehaviorSubject<ItemMeasurement> = new BehaviorSubject({} as ItemMeasurement)

  // administration
  public pageLoaded: IPageLoad = {
    '/tabs': new BehaviorSubject({ status: 0 }),
    '/tabs/map': new BehaviorSubject({ status: 0 }),
    '/tabs/loc': new BehaviorSubject({ status: 0 }),
    '/tabs/loc/l': new BehaviorSubject({ status: 0 }),
    '/tabs/loc/l/m': new BehaviorSubject({ status: 0 }),
  }

  constructor() { }

  /* Lifecycle */
  async onInit() {
    console.info('DataService.onInit()..')
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('DataService.init()..')
      await this.onInit()

      console.info('DataService: Ready')
      resolve()
    })
  }

  /* Derivatives */
  public async setLocationMeasurementsDisplay(reference: string) {
    // init
    let location: ItemLocation = this.location.value

    // edit
    for (let i = 0; i < location.measurements.length; i++) {
      location.measurements[i] = this.getMeasurementDisplay(
        location.measurements[i],
        location.hag,
        location.hamsl,
        reference
      )
    }

    // assign
    this.location.next(location)
  }

  public getMeasurementDisplay(measurement: any, hag: number | undefined, hamsl: number | undefined, reference: string) {
    // console.debug('getMeasurementDisplay()')

    if (reference == "top" || (reference == "hag" && hag == null) || (reference == "hamsl" && hamsl == null)) {
      // reviewed
      if (measurement.value_reviewed !== undefined && measurement.value_reviewed !== null) {
        measurement.displayValue = Math.round(measurement.value_reviewed * 100) / 100
        return measurement
      }

      // algo
      if (measurement.value_alg !== undefined && measurement.value_alg !== null) {
        measurement.displayValue = Math.round(measurement.value_alg * 100) / 100
        return measurement
      }
    }

    if (reference == "hag" && hag !== null) {
      // reviewed
      if (measurement.value_reviewed !== undefined && measurement.value_reviewed !== null) {
        measurement.displayValue = Math.round((measurement.value_reviewed + hag) * 100) / 100
        return measurement
      }

      // algo
      if (measurement.value_alg !== undefined && measurement.value_alg !== null) {
        measurement.displayValue = Math.round((measurement.value_alg + hag) * 100) / 100
        return measurement
      }
    }

    if (reference == "hamsl" && hamsl !== null) {
      // reviewed
      if (measurement.value_reviewed !== undefined && measurement.value_reviewed !== null) {
        measurement.displayValue = Math.round((measurement.value_reviewed + hamsl) * 100) / 100
        return measurement
      }

      // algo
      if (measurement.value_alg !== undefined && measurement.value_alg !== null) {
        measurement.displayValue = Math.round((measurement.value_alg + hamsl) * 100) / 100
        return measurement
      }
    }

    // default
    measurement.displayValue = null
    return measurement
  }
}
