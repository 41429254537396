import { Position } from '@capacitor/geolocation';
import { latLng } from 'leaflet';

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function roundTwoDec(value: number) {
  return Math.round(value * 100) / 100
}

export async function toPercentage(num: number) {
  return `${Math.round(num * 100)}%`
}

export function posToLatLng(position: Position) {
  return (latLng(position.coords.latitude, position.coords.longitude))
}