import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage-angular'
import {
  IAppFlow,
  IAppVersion,
  ICache,
  IPersonals,
  ISettings,
  ISettingsLocations,
  ISettingsMap,
  ISettingsMeasurements,
} from '../interfaces/cache'
import { version } from 'src/version/version'
import { Toast } from '@capacitor/toast'
import { Dialog } from '@capacitor/dialog'

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public timestampCompile: string
  public personals: IPersonals
  public cache: ICache

  // version update requires cache to be cleared
  public updateClearCacheRequired: boolean = true

  // getters
  private defaultPersonals: IPersonals = {
    username: '',
    token: '',
  }
  public appVersion: IAppVersion = version
  private defaultSettingsMap: ISettingsMap = {
    regions: [],
    display: 'all',
    zoom: 13,
    reference: 'hag',
  }
  private defaultSettingsLocations: ISettingsLocations = {
    projects: [],
    reference: 'hag',
  }
  private defaultSettingsMeasurements: ISettingsMeasurements = {
    pollInterval: 6000,
    volumeSoftware: 0.1,
    tuneSpeaker: 'left',
  }
  private defaultSettings: ISettings = {
    darkMode: false,
    map: this.defaultSettingsMap,
    locations: this.defaultSettingsLocations,
    measurements: this.defaultSettingsMeasurements,
  }
  private defaultAppFlow: IAppFlow = {
    showInstructionsFirstLoad: true,
  }

  constructor(public storage: Storage) {
    this.timestampCompile = this.appVersion.timestamp
    this.personals = this.defaultPersonals
    this.cache = {
      appVersion: this.appVersion,
      settings: this.defaultSettings,
      appFlow: this.defaultAppFlow,
    }
  }

  /* Lifecycle */
  async onInit() {
    console.info('CacheService.onInit()..')
  }

  // Init() is called from app.module.ts and will block the app until finished
  Init() {
    return new Promise<void>(async (resolve, reject) => {
      console.info('CacheService.init()..')
      await this.onInit()
      await this.createLocalStorage()
      await this.loadCacheFromLocalStorage()
      await this.checkInitialisePersonals()
      await this.checkInitialiseCache()
      console.info('CacheService: Ready')
      resolve()
    })
  }

  /* Initialization */
  async checkInitialisePersonals() {
    console.debug('checkInitialisePersonals()')
    let newPersonalsRequired: boolean = false

    if (!this.personals) {
      console.debug('No existing personals found..')
      newPersonalsRequired = true
    } else {
      console.debug('Existing personals found, see if it can be used..')

      try {
        if (typeof this.personals === 'undefined') {
          console.debug('No personals found, so new personals needed..')
          newPersonalsRequired = true
        }
      } catch (error) {
        console.debug(error)
        newPersonalsRequired = true
      }
    }

    if (newPersonalsRequired) {
      console.debug('Load default personals..')
      await this.initialisePersonals()
    } else {
      console.debug('Existing personals will be used..')
    }
  }

  async initialisePersonals() {
    console.debug('initialisePersonals()')
    this.personals = this.defaultPersonals
  }

  async checkInitialiseCache() {
    console.debug('checkInitialiseCache()')
    let newCacheRequired: boolean = false

    if (!this.cache) {
      console.debug('No existing cache found..')
      newCacheRequired = true
    } else {
      console.debug('Existing cache found, see if it can be used..')
      try {
        if (this.cache.appVersion.tag !== this.appVersion.tag && this.updateClearCacheRequired) {
          console.debug(
            'The app version does not match with the cached version, so new cache required to avoid bugs..'
          )
          newCacheRequired = true
          await Dialog.alert({
            title: 'Updated!',
            message: `
            The app has been updated!
            
            Your cache is cleared to avoid cache problems. So you may have to re-adjust your settings.
            `,
          })
        }
        if (typeof this.personals === 'undefined') {
          console.debug('No personals found, so new cache needed..')
          newCacheRequired = true
        }

        if (typeof this.cache.settings === 'undefined') {
          console.debug('No settings found, so new cache needed..')
          newCacheRequired = true
        }

        if (typeof this.cache.settings.map === 'undefined') {
          console.debug('No map settings found, so new cache needed..')
          newCacheRequired = true
        }

        if (typeof this.cache.settings.locations === 'undefined') {
          console.debug('No locations settings found, so new cache needed..')
          newCacheRequired = true
        }

        if (typeof this.cache.settings.measurements === 'undefined') {
          console.debug('No measurements settings found, so new cache needed..')
          newCacheRequired = true
        }

        if (typeof this.cache.appFlow === 'undefined') {
          console.debug('No appflow found, so new cache needed..')
          newCacheRequired = true
        }
      } catch (error) {
        console.debug(error)
        newCacheRequired = true
      }
    }

    if (newCacheRequired) {
      console.debug('Load default cache..')
      await this.initialiseCache()
    } else {
      console.debug('Existing cache will be used..')
    }
  }

  async initialiseCache() {
    console.debug('initialiseCache()')
    this.cache = {
      appVersion: this.appVersion,
      settings: this.defaultSettings,
      appFlow: this.defaultAppFlow,
    }
  }

  /* CACHE AND LOCAL STORAGE */
  async clearCache() {
    console.debug('clearCache()')
    await this.initialiseCache()
    await this.storage.set('cache', this.cache)
  }

  async clearLocalStorage() {
    console.debug('clearLocalStorage()')
    try {
      await this.storage.clear()
    } catch (error) {
      await this.handleError(error)
    }
  }

  async createLocalStorage() {
    console.debug('createLocalStorage()')
    try {
      await this.storage.create()
    } catch (error) {
      await this.handleError(error)
    }
  }

  async writeCacheToLocalStorage() {
    console.debug('writeCacheToLocalStorage()')
    try {
      await this.storage.set('personals', this.personals)
      await this.storage.set('cache', this.cache)
    } catch (error) {
      await this.handleError(error)
    }
  }

  async loadCacheFromLocalStorage() {
    console.debug('loadCacheFromLocalStorage()')
    try {
      this.personals = await this.storage.get('personals')
      this.cache = await this.storage.get('cache')
    } catch (error) {
      await this.handleError(error)
    }
  }

  checkUserHasToken() {
    console.debug('checkUserHasToken()')
    if (this.personals) {
      if (this.personals.token !== '') {
        console.debug('checkUserHasToken(): true')
        return true
      }
    }

    console.info('User has no token loaded')
    return false
  }

  /* Errors */
  async handleError(error: any) {
    console.debug('handleError()')
    console.error(error)
    Toast.show({
      text: error,
      duration: 'short',
      position: 'bottom',
    })
  }
}
