import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { ServiceWorkerModule } from '@angular/service-worker'
import { provideAnimations } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HomePage } from './pages/home/home.page'
import { AboutPage } from './pages/about/about'
import { LoginPage } from './pages/login/login.page'
import { PlatformService } from './services/platform.service'
import { CacheService } from './services/cache.service'
import { TrashService } from './services/trash.service'
import { DataService } from './services/data.service'
import { LoadingPage } from './pages/loading/loading'
import { ApiService } from './services/api.service'
import { ModalService } from './services/modal.service'
import { GeolocationService } from './services/geolocation.service'
import { PermissionsService } from './services/permissions.service'
import { NavigationService } from './services/navigation.service'

@NgModule({
  imports: [
    IonicModule.forRoot({
      animated: false,
      swipeBackEnabled: false,
    }),
    IonicStorageModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  exports: [IonicModule, IonicStorageModule, CommonModule, FormsModule],
})
export class AppSharedModule {}

@NgModule({
  declarations: [AppComponent, HomePage, AboutPage, LoginPage, LoadingPage],
  imports: [AppSharedModule, AppRoutingModule, BrowserModule, HttpClientModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppInSyncSequence,
      deps: [
        ApiService,
        CacheService,
        DataService,
        GeolocationService,
        ModalService,
        NavigationService,
        PermissionsService,
        PlatformService,
        TrashService,
      ],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideAnimations(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initializeAppInSyncSequence(
  apiService: ApiService,
  dataService: DataService,
  cacheService: CacheService,
  geolocationService: GeolocationService,
  modalService: ModalService,
  navigationService: NavigationService,
  permissionsService: PermissionsService,
  platformService: PlatformService,
  trashService: TrashService
) {
  return async (): Promise<any> => {
    console.info('Services: pre-loading..')
    await platformService.Init()
    await navigationService.Init()
    await apiService.Init()
    await cacheService.Init()
    await trashService.Init()
    await dataService.Init()
    await geolocationService.Init()
    await modalService.Init()
    await permissionsService.Init()
    return new Promise<void>(async (resolve, reject) => {
      console.info('Services: pre-loaded')
      resolve()
    })
  }
}
