<ion-header>
  <ion-toolbar>
    <ion-title>Loading</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="root.trunk.data.pageLoaded">
  <!-- <ion-content *ngIf="root.trunk.data.pageLoaded" scroll-y="false"> -->
  <ion-list *ngIf="root.trunk.trash.isTestUser">
    <ion-list-header>
      <ion-label>Settings</ion-label>
    </ion-list-header>
    <ion-item *ngFor="let item of (root.trunk.recordwav.audioStreamTrackSettingsJSON | keyvalue)">
      <ion-label>{{item.key}}</ion-label> {{item.value}}
    </ion-item>
  </ion-list>

  <ion-list *ngIf="root.trunk.trash.isTestUser">
    <ion-list-header>
      <ion-label>Capabilities</ion-label>
    </ion-list-header>
    <ion-item
      *ngFor="let item of (root.trunk.recordwav.audioStreamTrackCapabilitiesJSON | keyvalue)"
    >
      <ion-label>{{item.key}}</ion-label> {{item.value | json}}
    </ion-item>
  </ion-list>

  <div class="loading-bar">
    <p>
      <ion-spinner></ion-spinner>
    </p>

    <p>One moment please...</p>
    <ion-progress-bar
      *ngIf="root.trunk.trash.loadingDisplay=='bar'"
      [value]="root.trunk.trash.loadingValue"
    ></ion-progress-bar>
  </div>

  <ion-list *ngIf="root.trunk.trash.isTesting">
    <ion-item *ngFor="let item of root.trunk.data.pageLoaded | keyvalue originalOrder">
      <div [ngSwitch]="item.value.value.status">
        <ion-icon item-left color="danger" name="close-circle" *ngSwitchCase="0"></ion-icon>
        <ion-spinner item-left *ngSwitchCase="1"></ion-spinner>
        <ion-icon item-left color="success" name="checkmark-circle" *ngSwitchCase="2"></ion-icon>
      </div>
      <ion-label>
        <p>{{item.key}}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
