import { Component } from '@angular/core'
import { RootService } from './services/root.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public root: RootService) { }
}
